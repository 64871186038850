import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

const ModalDesktop = ({
  maxW,
  isOpen,
  onClose,
  size = 'lg',
  children,
}) => {
  const max = maxW ? { maxW } : {};

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size} isCentered scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent overflow='hidden' {...max}>
        <ModalCloseButton zIndex='20' />
        <ModalBody p={0} >
          {children}
        </ModalBody>
      </ModalContent>
    </Modal >
  );
};

ModalDesktop.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ModalDesktop;
